<template>
  <v-container>
    <v-stepper v-model="e6" vertical transparent>
      <v-stepper-step :complete="e6 > 1" step="1">
        {{$t('provide_task_id')}}
      </v-stepper-step>

      <v-stepper-content step="1">
        <p>{{$t('add_task')}}</p>

        <v-form ref="form">
          <v-text-field
            v-model="taskId"
            :rules="taskIdRules"
            label="Task ID"
          ></v-text-field>
        </v-form>

        <v-btn color="primary" @click="addProject">{{$t('continue')}}</v-btn>
        <!-- <v-btn @click="$router.go(-1)" text>Back</v-btn> -->
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">{{$t('confirmation')}}</v-stepper-step>

      <v-stepper-content step="2">
        <p>{{$t('successfully_added_task')}}</p>
      </v-stepper-content>
    </v-stepper>

  </v-container>
</template>

<script>
import { DataService } from "@/services/DataService";
const dataService = new DataService();

export default {
  data() {
    return {
      e6: 1,
      taskId: null,
      taskIdRules: [
        value => !!value || `${this.$t('task_id')} ${this.$t('required')}`,
        value => (value || '').length >= 6 || this.$t('min_characters', {1: 6}),
        value => (value || '').length <= 10 || this.$t('max_characters', {1: 10}),
      ]
    }
  },
  methods: {
    addProject() {
      if (this.$refs.form.validate()) {
        this.taskId = this.taskId.trim()

        let data = { 
          projectReferralCode: this.taskId, 
          userId: this.$store.state.userProfile._id 
        }

        this.$store.state.loader = true
        dataService.addProject(this.$store.state.token, data).then(res => {
          this.$store.state.loader = false
          
          if (!(res.error && res.error == true)) {
            this.e6 = 2
          }
        })
      }
    }
  }
}
</script>